import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getBalance, getTonPrice } from '../../helpers/api';

interface TonState {
  balance: number | null;
  tonPrice: number | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: TonState = {
  balance: null,
  tonPrice: null,
  isLoading: false,
  error: null,
};

export const fetchTonData = createAsyncThunk(
  'ton/fetchTonData',
  async (address: string | null, { rejectWithValue }) => {
    try {
      const tonPrice = await getTonPrice();
      const balance = address ? await getBalance(address) : null;
      return { balance, tonPrice };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const tonSlice = createSlice({
  name: 'ton',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTonData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchTonData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.balance = action.payload.balance;
        state.tonPrice = action.payload.tonPrice;
      })
      .addCase(fetchTonData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export default tonSlice.reducer;
