import axios from "axios";
import { LoanFormValues, UserResponse, WithdrawalData, PaymentWalletResponse, partnerLoanData } from '../types';


export const validateHash = async (hash: string): Promise<boolean> => {
  try {
    await axios.post('/api/validate-hash.php', { hash });
    return true;
  } catch (error) {
    console.error('Error validating hash:', error);
    return false;
  }
};

export const addUser = async (userData: any, startParam: string, initData: string): Promise<UserResponse> => {
  const response = await axios.post('/api/add-user.php', { ...userData, startParam, initData });
  return response.data;
};


export const saveWalletToDatabase = async (walletData: any) => {
  try {
    const response = await axios.post('/api/saveTonWallet.php', walletData);
    if (response.status !== 200) {
      console.error('Failed to save wallet');
    }
  } catch (error) {
    console.error('Error saving wallet:', error);
  }
};

export const getTransactions = async (page: number, pageSize: number) => {
  try {
    const response = await axios.post("/api/webTokens/getTransactions.php", {
      page: page,
      pageSize: pageSize,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching transactions:", error);
    throw error;
  }
};

// Функция для получения займов
export const fetchLoans = async (page: number, itemsPerPage: number) => {
  try {
    const response = await axios.post(`/api/loans/loans.php`, {
      page: page,
      itemsPerPage: itemsPerPage
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching loans", error);
    throw error;
  }
};

// Функция для создания займа
export const createLoan = async (data: LoanFormValues) => {
  try {
    const response = await axios.post('/api/loans/create_loan.php', data);
    return response.data;
  } catch (error) {
    console.error("Error submitting loan", error);
    throw error;
  }
};
// Функция для отмены займа
export const cancelLoan = async (loanId: number) => {
  try {
    const response = await axios.post('/api/loans/cancel_loan.php', {
      loanId,
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
    return response.data;
  } catch (error) {
    throw new Error('Error cancelling loan');
  }
};


export const createInvoice = async (wallet: string, amount: number) => {
  try {
    const response = await axios.post('/api/nft/getLinkToPay.php', {
      wallet,
      amount,
    });

    if (response.data.status === 'success') {
      return response.data.invoice_url;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw new Error('Failed to create invoice');
  }
};

export async function getJettonBalance(address: string) {
  const response = await fetch(
      `https://tonapi.io/v2/accounts/${address}/jettons/EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs`,
  );
  const data = await response.json();
  return data.balance;
}

export const fetchUserLoans = async (page: number, itemsPerPage: number) => {
  try {
    const response = await axios.post("/api/dashboard/user-loans.php", { page, itemsPerPage });
    return response.data;
  } catch (error) {
    throw new Error("Error fetching user loans");
  }
};

export const fetchUserTransactions = async (page: number, itemsPerPage: number) => {
  try {
    const response = await axios.post("/api/dashboard/user-transactions.php", { page, itemsPerPage });
    return response.data;
  } catch (error) {
    throw new Error("Error fetching user transactions");
  }
};

export const changeLoanRate = async (loanId: number, newRate: number) => {
  try {
    const response = await axios.post("/api/change-loan-rate.php", { loanId, newRate });
    return response.data;
  } catch (error) {
    throw new Error("Error changing loan rate");
  }
};

export const fetchWithdrawals = async (page: number, itemsPerPage: number) => {
  try {
    const response = await axios.post('/api/withdraw/get-withdrawals.php', {
      page,
      itemsPerPage,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching withdrawals:', error);
    throw error;
  }
};

export const getBalance = async (address: string): Promise<number | null> => {
  const response = await fetch(`https://toncenter.com/api/v3/account?address=${address}`);
  if (!response.ok) {
    return null;
  }
  const data = await response.json();
  const nanotonBalance = parseInt(data.balance, 10);
  return nanotonBalance / 1e9;
};

export const getTonPrice = async (): Promise<number> => {
  const response = await fetch("https://tonapi.io/v2/rates?tokens=ton&currencies=usd");
  if (!response.ok) {
    throw new Error(`Failed to fetch`);
  }
  const data = await response.json();
  if (data.rates && data.rates.TON) {
    return data.rates.TON.prices.USD;
  }
  throw new Error("Failed to fetch TON price");
};

export const fetchLoansData = async () => {
  try {
    const response = await axios.post("/api/loans/fetch_pending_loans.php");
    return response.data;
  } catch (error) {
    throw new Error("Error fetching user loans");
  }
};

export const getAvailableAmount = async () => {
  try {
    const response = await axios.post("/api/withdraw/get-available-amount.php");
    return response.data;
  } catch (error) {
    throw new Error("Error fetching");
  }
};

export const withdrawFunds = async (withdrawalData: WithdrawalData) => {
  try {
    const response = await axios.post("/api/withdraw/withdraw-funds.php", withdrawalData);
    return response.data;
  } catch (error) {
    throw new Error("Error fetching");
  }
};

export const fetchPartners = async (page: number, itemsPerPage: number) => {
  try {
    const response = await axios.post('/api/partners/get-partners.php', {
      page,
      itemsPerPage,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching withdrawals:', error);
    throw error;
  }
};

export const fetchTaps = async () => {
  try {
    const response = await axios.post("/api/game/taps.php");
    return response.data;
  } catch (error) {
    throw new Error("Error fetching taps");
  }
};

export async function getJettonWalletAddress(userWalletAddress: string, jettonMasterAddress: string) {
  try {
      const url = `https://tonapi.io/v2/accounts/${userWalletAddress}/jettons/${jettonMasterAddress}?currencies=ton,usd`;
      const response = await fetch(url);
      if (response.ok) {
          const data = await response.json();
          return data;
      } else {
          console.error(`Error fetching wallet address: ${response.statusText}`);
      }
  } catch (error) {
      console.error("Error fetching wallet address:", error);
  }
}

export const getPaymentWallet = async (walletAddress: string): Promise<PaymentWalletResponse> => {
  try {
    const response = await axios.post<PaymentWalletResponse>('/api/payments/get-payment-wallet.php', { current_wallet: walletAddress });
    return response.data;
  } catch (error) {
    console.error('Error fetching payment wallet:', error);
    throw error;
  }
};

export const getAvailableAmountToInvest = async () => {
  try {
    const response = await axios.post("/api/partners/get-available-amount-to-invest.php");
    return response.data;
  } catch (error) {
    throw new Error("Error fetching");
  }
};

export const InvestPartners = async (partnerLoanData: partnerLoanData) => {
  try {
    const response = await axios.post("/api/loans/invest_partners_loans.php", partnerLoanData);
    return response.data;
  } catch (error) {
    throw new Error("Error fetching");
  }
};

export const getNotifications = async () => {
  try {
    const response = await axios.post('/api/notifications/get_notifications.php');
    return response.data.notifications;
  } catch (error) {
    console.error("Error fetching notifications:", error);
    throw error;
  }
};

export const notificationsAsRead = async () => {
  try {
    const response = await axios.post("/api/notifications/notifications_as_read.php");
    return response.data;
  } catch (error) {
    console.error("Error marking notification as read:", error);
    throw error;
  }
};

export const getPendingAmount = async () => {
  try {
    const response = await axios.post("/api/withdraw/get-pending-amount.php");
    return response.data;
  } catch (error) {
    throw new Error("Error fetching");
  }
};