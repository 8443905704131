import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Dashboard from "../components/dashboard";
import Loans from "../components/loans";
import Balances from "../components/balances";
import TransactionHistory from "../components/history";
import ModeSwitch from "../components/ModeSwitch";
import { useSelector } from "react-redux";
import {
  SettingTwoTone,
  PieChartTwoTone,
  DashboardTwoTone,
  BankTwoTone,
  DatabaseTwoTone,
  BellTwoTone,
  QuestionCircleTwoTone
} from "@ant-design/icons";
import { Row, Badge } from "antd";
import { RootState } from "../services/store";

const Home: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userData, isLoading } = useSelector((state: RootState) => state.user);
  const mode = useSelector((state: RootState) => state.app.mode);

  const tabs = [
    {
      id: "dashboard",
      text: t("dashboard"),
      icon: <DashboardTwoTone style={{ fontSize: "16px" }} />,
    },
    {
      id: "loans",
      text: t("Loans"),
      icon: <BankTwoTone style={{ fontSize: "16px" }} />,
    },
    {
      id: "history",
      text: t("history"),
      icon: <DatabaseTwoTone style={{ fontSize: "16px" }} />,
    },
  ];

  const [currentTab, setCurrentTab] = useState(tabs[0].id);

  const handleTabClick = (value: string | number) => {
    setCurrentTab(value as string);
  };

  return (
    userData && (
      <>
        <div className="header">
          <ModeSwitch />
          <div>
            {mode === "Full" ? (
              <PieChartTwoTone
                onClick={() => navigate("/statistics")}
                style={{ fontSize: "24px", cursor: "pointer", marginRight: 10 }}
              />
            ) : (
              <QuestionCircleTwoTone
                onClick={() => navigate("/settings/faq")}
                style={{ fontSize: "24px", cursor: "pointer", marginRight: 10 }}
              />
            )}
            <Badge count={!isLoading && userData.unread_notifications}>
              <BellTwoTone
                onClick={() => navigate("/notifications")}
                style={{
                  fontSize: "24px",
                  cursor: "pointer",
                }}
              />
            </Badge>
            <SettingTwoTone
              onClick={() => navigate("/settings")}
              style={{ fontSize: "24px", cursor: "pointer", marginLeft: 10 }}
            />
          </div>
        </div>
        <Balances />

        <div className="tabs">
          <Row gutter={16} className="tabs_content">
            {tabs.map((tab) => (
              <div
                className="tabs_panel"
                onClick={() => handleTabClick(tab.id)}
              >
                {tab.icon}
                {tab.text}
              </div>
            ))}
          </Row>

          {currentTab === "dashboard" && <Dashboard />}
          {currentTab === "loans" && <Loans />}
          {currentTab === "history" && <TransactionHistory />}
        </div>
      </>
    )
  );
};

export default Home;
