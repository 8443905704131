import { Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setMode } from '../services/slices/appSlice';
import { RootState } from '../services/store';

const ModeSwitch = () => {
  const dispatch = useDispatch();
  const mode = useSelector((state: RootState) => state.app.mode);

  const handleToggle = (checked: boolean) => {
    const newMode = checked ? 'Full' : 'Basic';
    dispatch(setMode(newMode));
  };

  return (
    <div className="mode_switch">
      <Switch
        checkedChildren="Full"
        unCheckedChildren="Basic"
        checked={mode === 'Full'}
        onChange={handleToggle}
      />
    </div>
  );
};

export default ModeSwitch;
