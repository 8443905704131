import { FC, useState, useEffect, useRef } from "react";
import {
  Typography,
  Card,
  message,
  Badge,
  Spin,
  InputNumber,
  Row,
  Col,
  Modal,
  Button,
  List,
} from "antd";
import WebApp from "@twa-dev/sdk";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import axios from "axios";
import { AppDispatch } from "../services/store";
import { updateWebTokens } from "../services/slices/userSlice";
import { QuestionCircleTwoTone } from "@ant-design/icons";
import { Task } from "../types";

const { Title } = Typography;


const Tasks: FC = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [codeInput, setCodeInput] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDescriptionModalVisible, setIsDescriptionModalVisible] =
    useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState<number | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    WebApp.BackButton.show();
    WebApp.BackButton.onClick(() => window.history.back());

    const fetchTasks = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `/api/tasks.php?action=list`
        );
        setTasks(response.data);
      } catch (error) {
        console.error("Error fetching tasks:", error);
        message.error(t("tasksFetchError"));
      }
      setIsLoading(false);
    };

    fetchTasks();

    return () => {
      WebApp.BackButton.hide();
    };
  }, []);

  const handleSubmitCode = async () => {
    if (!codeInput) {
      message.error(t("codeInputEmptyError"));
      return;
    }

    if (selectedTaskId === null) {
      message.error(t("taskNotSelectedError"));
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post("/api/tasks.php?action=check_code", {
        task_id: selectedTaskId,
        code: codeInput,
      });

      if (response.data) {
        const { status, message: responseMessage } = response.data;
        if (status === "success") {
          dispatch(updateWebTokens(10000));
          message.success(t(responseMessage));
          setTasks((prevTasks) =>
            prevTasks.filter((task) => task.id !== selectedTaskId)
          );
        } else {
          message.error(t(responseMessage));
        }
      } else {
        message.error(t("codeSubmitError"));
      }
    } catch (error: unknown) {
      console.error("Error submitting code:", error);
      if (axios.isAxiosError(error)) {
        message.error(error.response?.data?.message || t("codeSubmitError"));
      } else if (error instanceof Error) {
        message.error(error.message);
      } else {
        message.error(t("codeSubmitError"));
      }
    } finally {
      setIsLoading(false);
      setCodeInput("");
      setIsModalVisible(false);
    }
  };

  const showCodeModal = (taskId: number) => {
    setSelectedTaskId(taskId);
    setIsModalVisible(true);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCodeInput("");
  };

  const showDescriptionModal = () => {
    setIsDescriptionModalVisible(true);
  };

  const handleCancelDescriptionModal = () => {
    setIsDescriptionModalVisible(false);
  };

  const handleButtonClick = (value: string) => {
    if (codeInput.length < 10) {
      setCodeInput(codeInput + value);
    }
  };

  const handleClear = () => {
    setCodeInput("");
  };


  if (isLoading) {
    return (
      <div className="loader-container">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div>
      <Button
        type="link"
        icon={<QuestionCircleTwoTone />}
        onClick={showDescriptionModal}
        style={{ float: "right" }}
      />
      <Title level={3}>{t("tasksTitle")}</Title>

      <List
        grid={{ gutter: 16, column: 1 }}
        dataSource={tasks}
        renderItem={(task) => (
          <List.Item key={task.id}>
            <Badge.Ribbon text="+10 000 $WEB">
              <Card
                className="buttons"
                actions={[
                  <Typography.Text
                    key="setting"
                    onClick={() => showCodeModal(task.id)}
                  >
                    {t("Check")}
                  </Typography.Text>,
                ]}
              >
                <div className="card">
                  <Typography.Link
                    strong
                    onClick={() => WebApp.openLink(task.social_media_link)}
                  >
                    {task.task_name}
                  </Typography.Link>
                </div>
              </Card>
            </Badge.Ribbon>
          </List.Item>
        )}
      />

      <Modal
        title={t("verify code")}
        open={isModalVisible}
        onOk={handleSubmitCode}
        okText={t("Submit")}
        cancelText={t("Cancel")}
        onCancel={handleCancel}
        confirmLoading={isLoading}
      >
        <InputNumber
          placeholder={t("enter code")}
          value={codeInput}
          onChange={(value) => setCodeInput(value?.toString() || "")}
          style={{ width: "100%", marginBottom: 16 }}
        />

        <Row gutter={[16, 16]} justify={"center"}>
          {[...Array(10)].map((_, i) => (
            <Col>
              <Button key={i} onClick={() => handleButtonClick(i.toString())}>
                {i}
              </Button>
            </Col>
          ))}
          <Col>
            <Button onClick={handleClear}>{t("Clear")}</Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        title={t("Task Instructions")}
        open={isDescriptionModalVisible}
        onOk={handleCancelDescriptionModal}
        onCancel={handleCancelDescriptionModal}
        footer={[
          <Button key="back" onClick={handleCancelDescriptionModal}>
            {t("Close")}
          </Button>,
        ]}
      >
        <Typography.Paragraph>
          {" "}
          <span
            dangerouslySetInnerHTML={{
              __html: t("TaskDescription"),
            }}
          />
        </Typography.Paragraph>
      </Modal>
    </div>
  );
};

export default Tasks;
