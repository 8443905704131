import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoanCertificate from "../components/loanCertificate";
import { fetchUserLoans, changeLoanRate } from "../helpers/api";
import { useSelector } from "react-redux";
import { RootState } from "../services/store";
import {
  List,
  Typography,
  Spin,
  Modal,
  Tag,
  Pagination,
  Row,
  Col,
  Button,
  InputNumber,
  message,
  Alert,
  Statistic,
  Rate,
} from "antd";
import { Loan } from "../types";

const { Text } = Typography;

const Loans: React.FC = () => {
  const { t } = useTranslation();
  const { userData } = useSelector((state: RootState) => state.user);
  const [loans, setLoans] = useState<Loan[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedLoan, setSelectedLoan] = useState<Loan | null>(null);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [newRate, setNewRate] = useState<number | null>(null);
  const [rating, setRating] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    if (!userData) return;
    setLoading(true);

    fetchUserLoans(page, itemsPerPage)
      .then((data) => {
        setLoans(data.loans);
        setTotalPages(data.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error.message);
        setLoading(false);
      });
  }, [userData, page]);

  const handleShowModal = (loan: Loan) => {
    setSelectedLoan(loan);
    setNewRate(loan.rate);
    setModalVisible(true);
  };

  const handleCancelModal = () => {
    setModalVisible(false);
    setRating(0);
  };

  const handleRateChange = () => {
    if (!selectedLoan || !userData || newRate === null) return;

    changeLoanRate(selectedLoan.loan_id, newRate)
      .then((response) => {
        if (response.success) {
          setLoans((prevLoans) =>
            prevLoans.map((loan) =>
              loan.loan_id === selectedLoan.loan_id
                ? { ...loan, rate: newRate }
                : loan
            )
          );
          setModalVisible(false);
          message.success(t("Success"));
        } else {
          message.error(t("Error"));
          console.error("Error changing loan rate:", response.error);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "active":
        return "green";
      case "pending":
        return "orange";
      case "cancelled":
        return "red";
      case "completed":
        return "blue";
      default:
        return "gray";
    }
  };

  return (
    <div>
      <Typography.Title level={4}>{t("Loans")}</Typography.Title>
      {loading ? (
        <Spin size="large" />
      ) : (
        <>
          <List
            itemLayout="horizontal"
            dataSource={loans}
            renderItem={(loan) => (
              <List.Item
                onClick={() => handleShowModal(loan)}
                style={{ cursor: "pointer", justifyContent: "space-between" }}
              >
                <List.Item.Meta
                  title={
                    <div className="history-item">
                      <Text>
                        {loan.used_bonus ? (
                          `$ ${loan.amount}`
                        ) : (
                          <>
                          {loan.currency === "USDT" ?
                          <img src="./assets/UsdtLogo.webp" alt="USDT Logo" width={12} style={{ marginRight: 5 }}/> :
                            <img
                              src="./assets/tonIcon.png"
                              alt="Logo"
                              width={12}
                              style={{ marginRight: 5 }}
                            />
                          }
                          {loan.amount}
                          </>
                        )}
                      </Text>
                      <Text
                        type="secondary"
                        style={{ fontSize: 12, fontWeight: "normal" }}
                      >
                        {t("Rate")}: {loan.rate}% / {t("Profit")}: {(loan.profit - loan.insurance_pool_contribution).toFixed(2)}
                      </Text>
                    </div>
                  }
                />
                <Tag color={getStatusColor(loan.status)}>
                  {t(`loan_status.${loan.status}`)}
                </Tag>
              </List.Item>
            )}
            locale={{ emptyText: t("You don't have any loans yet") }}
          />

          <Row justify="center" style={{ marginTop: 20 }}>
            <Col>
              <Pagination
                current={page}
                total={totalPages * itemsPerPage}
                pageSize={itemsPerPage}
                onChange={handlePageChange}
                showSizeChanger={false}
              />
            </Col>
          </Row>
        </>
      )}

      <Modal
        title={t("Loan Details")}
        open={modalVisible}
        onCancel={handleCancelModal}
        footer={[
          <Button key="back" onClick={handleCancelModal}>
            {t("Close")}
          </Button>,
          selectedLoan?.status === "pending" && (
            <Button key="changeRate" type="primary" onClick={handleRateChange}>
              {t("Change Rate")}
            </Button>
          ),
        ]}
      >
        {selectedLoan && (
          <>
            <Row gutter={24} justify="center">
            {selectedLoan.margin > 0 && <Alert message={`${t("A margin loan has been used")} ${selectedLoan.margin }%`} type="success" showIcon />}
              <Col span={12}>
                <Statistic
                  title={t("Rate")}
                  value={selectedLoan.rate}
                  precision={2}
                  valueStyle={{ textAlign: "center" }}
                  suffix="%"
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title={t("Profit")}
                  value={`+${selectedLoan.profit}`}
                  precision={2}
                  valueStyle={{ color: "#3f8600", textAlign: "center" }}
                  prefix={
                    selectedLoan.used_bonus ? (
                      "$"
                    ) : (
                      <>
                      {selectedLoan.currency === "USDT" ?
                      <img src="./assets/UsdtLogo.webp" alt="USDT Logo" width={20}/> :
                        <img
                          src="./assets/tonIcon.png"
                          alt="Logo"
                          width={20}
                        />
                      }
                      </>
                    )
                  }
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title={t("To be refunded")}
                  value={selectedLoan.total_repayment}
                  precision={2}
                  valueStyle={{ textAlign: "center" }}
                  prefix={
                    selectedLoan.used_bonus ? (
                      "$"
                    ) : (
                      selectedLoan.currency === "USDT" ?
                        <img src="./assets/UsdtLogo.webp" alt="USDT Logo" width={20}/> :
                          <img
                            src="./assets/tonIcon.png"
                            alt="Logo"
                            width={20}
                          />
                    )
                  }
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title={t("Insurance")}
                  value={`-${selectedLoan.insurance_pool_contribution}`}
                  precision={2}
                  valueStyle={{ color: "#cf1322", textAlign: "center" }}
                  prefix={
                    selectedLoan.used_bonus ? (
                      "$"
                    ) : (
                      selectedLoan.currency === "USDT" ?
                      <img src="./assets/UsdtLogo.webp" alt="USDT Logo" width={20}/> :
                        <img
                          src="./assets/tonIcon.png"
                          alt="Logo"
                          width={20}
                        />
                    )
                  }
                />
              </Col>
            </Row>
            {selectedLoan?.status === "pending" && (
              <Row justify="space-evenly" style={{ margin: 20 }} align="middle">
                <Text>{t("New rate")}:</Text>
                <InputNumber
                  min={0}
                  max={100}
                  value={newRate}
                  onChange={setNewRate}
                  decimalSeparator="."
                  formatter={(value) => {
                    if (value === undefined) return "";
                    return `${value}`.replace(",", ".");
                  }}
                  parser={(value) => {
                    if (value === undefined) return 0;
                    return parseFloat(value.replace(",", "."));
                  }}
                />
              </Row>
            )}
            {(selectedLoan?.status === "active" ||
              selectedLoan?.status === "completed") && (
              <LoanCertificate
                loanNumber={selectedLoan.loan_id.toString()}
                loanAmount={selectedLoan.amount}
                loanEndDate={selectedLoan.expires_at.substring(0, 10)}
                loanDate={selectedLoan.created_at.substring(0, 10)}
              />
            )}
            {selectedLoan?.pledged && (
              <Alert
                message={t("This certificate is pledged")}
                type="warning"
                showIcon
              />
            )}
            {selectedLoan?.status === "completed" && (
              <Rate
                className="loan-stars"
                value={rating}
                onChange={(value) => {
                  setRating(value);
                  message.success(t("Thanks"));
                }}
              />
            )}
          </>
        )}
      </Modal>
    </div>
  );
};

export default Loans;
