import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Switch, List, message, Spin, Typography } from 'antd';
import axios from 'axios';
import WebApp from "@twa-dev/sdk";

const { Title } = Typography;
type NotificationKeys = 'profileSubscription' | 'loanReturn' | 'newReferral' | 'newMessage';

interface NotificationSetting {
  label: string;
  key: NotificationKeys;
}

const NotificationSettings = () => {
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState<{
    profileSubscription: boolean;
    loanReturn: boolean;
    newReferral: boolean;
    newMessage: boolean;
  }>({
    profileSubscription: true,
    loanReturn: true,
    newReferral: true,
    newMessage: true,
  });

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    WebApp.BackButton.show();
    WebApp.BackButton.onClick(() => window.history.back());

    return () => {
      WebApp.BackButton.hide();
    };
  }, []);

  useEffect(() => {
    axios.post('/api/user/notifications/get_info_notifications.php')
      .then(response => {
        setNotifications(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error("Error fetching notification settings", error);
        setError("Failed to load notification settings");
        setLoading(false);
      });
  }, []);

  const handleNotificationChange = (key: NotificationKeys, value: boolean) => {
    const updatedNotifications = { ...notifications, [key]: value };
    setNotifications(updatedNotifications);

    axios.post('/api/user/notifications/update.php', updatedNotifications)
      .then(() => {
        message.success('Notification settings updated');
      })
      .catch(error => {
        message.error("Error updating notification settings");
        console.error("Error updating notification settings", error);
      });
  };

  if (loading) return <Spin size="large" />;
  if (error) return <div>{error}</div>;

  const notificationItems: NotificationSetting[] = [
    { label: t('Profile Subscription'), key: 'profileSubscription' },
    { label: t('Loan Return'), key: 'loanReturn' },
    { label: t('New Referral Registration'), key: 'newReferral' },
    { label: t('New Message'), key: 'newMessage' },
  ];

  return (
    <>
    <Title level={5}>{t("notifications")}</Title>
    <List
      itemLayout="horizontal"
      dataSource={notificationItems}
      renderItem={item => (
        <List.Item
          actions={[
            <Switch
              checked={notifications[item.key]}
              onChange={(checked) => handleNotificationChange(item.key, checked)}
            />
          ]}
        >
          <List.Item.Meta title={item.label} />
        </List.Item>
      )}
    />
    </>
  );
};

export default NotificationSettings;
