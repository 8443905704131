import { createReducer } from '@reduxjs/toolkit';
import { knockBank, receiveLoan, resetGame, setNextAvailableTapTime } from './actions';

interface BankState {
  knocks: number;
  loanReceived: boolean;
  nextAvailableTapTime: number | null;
}

const initialState: BankState = {
  knocks: 0,
  loanReceived: false,
  nextAvailableTapTime: null,
};

const bankReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(knockBank, (state) => {
      state.knocks += 1;
    })
    .addCase(receiveLoan, (state) => {
      state.loanReceived = true;
      state.knocks = 0;
      state.nextAvailableTapTime = Date.now() + 15 * 60 * 1000;
    })
    .addCase(resetGame, (state) => {
      state.knocks = 0;
      state.loanReceived = false;
    })
    .addCase(setNextAvailableTapTime, (state, action) => {
      state.nextAvailableTapTime = action.payload;
    });
});

export default bankReducer;
