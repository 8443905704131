import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  Input,
  Button,
  Upload,
  message,
  Modal,
  List,
  Dropdown,
} from "antd";
import {
  CameraOutlined,
  MehOutlined,
  BarChartOutlined,
  SendOutlined,
  LinkOutlined,
  LoadingOutlined
} from "@ant-design/icons";
import Picker from "@emoji-mart/react";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState, AppDispatch } from "../services/store";
import { updateBonusBalance } from "../services/slices/userSlice";
import { AddPostProps } from "../types";
import type { MenuProps } from "antd";

const { TextArea } = Input;

const AddPost: FC<AddPostProps> = ({ onPostAdded }) => {
  const { t } = useTranslation();
  const [content, setContent] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);
  const [pollVisible, setPollVisible] = useState(false);
  const [pollQuestion, setPollQuestion] = useState("");
  const [pollOptions, setPollOptions] = useState<string[]>([""]);
  const { userData } = useSelector((state: RootState) => state.user);
  const dispatch: AppDispatch = useDispatch();

  const handleAddPost = async () => {
    if (!content.trim()) {
      message.error("Post content cannot be empty");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post("/api/account/add_post.php", {
        content,
        image_url: imageUrl,
        poll_question: pollQuestion,
        poll_options: pollOptions.filter((opt) => opt.trim() !== ""),
      });
      setContent("");
      setImageUrl(null);
      setFileList([]);
      setPollQuestion("");
      setPollOptions([""]);
      dispatch(updateBonusBalance(response.data.bonus_added));
      message.success("Post added successfully!");
      onPostAdded();
    } catch (error) {
      message.error("Failed to add post");
    } finally {
      setLoading(false);
    }
  };

  const handleEmojiSelect = (emoji: any) => {
    setContent(content + emoji.native);
  };

  const handleChange = (info: any) => {
    if (info.file.status === "removed") {
      setImageUrl(null);
    }
  };

  const beforeUpload = (file: any) => {
    const reader = new FileReader();
    reader.onload = () => setImageUrl(reader.result as string);
    reader.readAsDataURL(file);

    setFileList([file]);

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return false;
  };

  const addPollOption = () => {
    setPollOptions([...pollOptions, ""]);
  };

  const handlePollOptionChange = (value: string, index: number) => {
    const newOptions = [...pollOptions];
    newOptions[index] = value;
    setPollOptions(newOptions);
  };

  const handleRemovePollOption = (index: number) => {
    const newOptions = pollOptions.filter((_, i) => i !== index);
    setPollOptions(newOptions);
  };

  const optionsMenu: MenuProps = {
    items: [
      {
        key: "1",
        label: (
          <Upload
            maxCount={1}
            fileList={fileList}
            onChange={handleChange}
            beforeUpload={beforeUpload}
            onRemove={() => setFileList([])}
          >
            {fileList.length === 1 ? null : t("Upload Image")}
          </Upload>
        ),
        icon: <CameraOutlined />,
      },
      {
        key: "2",
        label: (
          <span onClick={() => setEmojiPickerVisible(!emojiPickerVisible)}>
            {t("Add Emoji")}
          </span>
        ),
        icon: <MehOutlined />,
      },
      {
        key: "3",
        label: (
          <span
            onClick={() => {
              if (userData?.premium_status === 1) {
                setPollVisible(true);
              }
            }}
            style={{
              cursor:
                userData?.premium_status === 1 ? "pointer" : "not-allowed",
            }}
          >
            {t("Add Poll")}
          </span>
        ),
        disabled: userData?.premium_status !== 1,
        icon: <BarChartOutlined />,
      },
    ],
  };

  return (
    <div>
      <div className="create_posts">
        <Dropdown
          menu={optionsMenu}
          trigger={["click"]}
          onOpenChange={(open) => setShowOptions(open)}
        >
          <LinkOutlined />
        </Dropdown>
        <TextArea
          rows={1}
          maxLength={1000}
          showCount
          value={content}
          onChange={(e) => setContent(e.target.value)}
          placeholder="What's happening?"
          autoSize={{ minRows: 1, maxRows: 10 }}
          className="create_posts_input"
        />
        {loading ?  <LoadingOutlined /> : <SendOutlined onClick={handleAddPost}/>}
      </div>
      {emojiPickerVisible && <Picker onEmojiSelect={handleEmojiSelect} />}

      <Modal
        title={t("Create Poll")}
        open={pollVisible}
        onCancel={() => setPollVisible(false)}
        onOk={() => setPollVisible(false)}
        footer={[
          <Button key="back" onClick={() => setPollVisible(false)}>
            {t("Cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => setPollVisible(false)}
          >
            {t("Done")}
          </Button>,
        ]}
      >
        <Input
          placeholder={t("Poll Question")}
          value={pollQuestion}
          onChange={(e) => setPollQuestion(e.target.value)}
          style={{ marginBottom: 20 }}
        />
        <List
          dataSource={pollOptions}
          renderItem={(option, index) => (
            <List.Item
              actions={[
                <Button
                  type="link"
                  danger
                  onClick={() => handleRemovePollOption(index)}
                >
                  {t("Remove")}
                </Button>,
              ]}
            >
              <Input
                placeholder={t(`Option ${index + 1}`)}
                value={option}
                onChange={(e) => handlePollOptionChange(e.target.value, index)}
              />
            </List.Item>
          )}
        />
        <Button type="dashed" onClick={addPollOption} style={{ width: "100%" }}>
          {t("Add Option")}
        </Button>
      </Modal>
    </div>
  );
};

export default AddPost;
