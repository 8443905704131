import { FC, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Statistic, message, Spin } from "antd";
import { useTranslation } from "react-i18next";
import WebApp from "@twa-dev/sdk";
import { RootState, AppDispatch } from "../../services/store";
import { updateWebTokens } from "../../services/slices/userSlice";
import { fetchTaps } from "../../helpers/api";
import {
  knockBank,
  receiveLoan,
  resetGame,
  setNextAvailableTapTime,
} from "../../services/actions";

const { Title, Text } = Typography;
const { Countdown } = Statistic;

const BankGame: FC = () => {
  const knocks = useSelector((state: RootState) => state.game.knocks);
  const nextAvailableTapTime = useSelector(
    (state: RootState) => state.game.nextAvailableTapTime
  );
  const { userData } = useSelector((state: RootState) => state.user);
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const [showPoints, setShowPoints] = useState(false);
  const [animationKey, setAnimationKey] = useState(0);
  const [currentTime, setCurrentTime] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    WebApp.BackButton.show();
    WebApp.BackButton.onClick(() => window.history.back());

    return () => {
      WebApp.BackButton.hide();
    };
  }, []);

  useEffect(() => {
    const fetchNextAvailableTapTime = async () => {
      setLoading(true);
      if (userData) {
        const response = await fetch(
          `/api/game/getNextAvailableTapTime.php?userId=${userData.id}`
        );
        const result = await response.json();
        if (result.success && result.nextAvailableTapTime) {
          const serverTime = new Date(result.nextAvailableTapTime).getTime();
          setCurrentTime(new Date(result.nowTime).getTime());
          dispatch(setNextAvailableTapTime(serverTime));
        } else {
          setCurrentTime(Date.now());
        }
        setLoading(false);
      }
    };

    fetchNextAvailableTapTime();
  }, [userData, dispatch]);

  const handleKnockBank = async () => {
    if (knocks < 10) {
      dispatch(knockBank());
      setAnimationKey((prev) => prev + 1);
      setShowPoints(true);
      setTimeout(() => setShowPoints(false), 1000);
    }
    if (knocks === 9) {
      try {
        const result = await fetchTaps();
        if (result.success) {
          message.success(t("Farming.Congratulations"));
          dispatch(receiveLoan());
          dispatch(updateWebTokens(10000));
          const newNextAvailableTapTime = Date.now() + 15 * 60 * 1000;
          dispatch(setNextAvailableTapTime(newNextAvailableTapTime));
          setCurrentTime(Date.now());
        } else {
          message.error(result.message);
        }
      } catch (error) {
        message.error("An error occurred while processing your request.");
        console.error("Error:", error);
      }
    }
  };

  const onFinish = () => {
    dispatch(resetGame());
    dispatch(setNextAvailableTapTime(0));
    setCurrentTime(Date.now());
  };

  const getCountdownValue = () => {
    if (nextAvailableTapTime && currentTime) {
      const remainingTime = nextAvailableTapTime - currentTime;
      if (remainingTime > 0) {
        return nextAvailableTapTime;
      }
    }
    return undefined;
  };

  if (loading) {
    return (
      <div className="loader-container">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div>
      <Title level={3}>{t("Farming.Title")}</Title>
      {getCountdownValue() !== undefined ? (
        <Countdown
          title={t("Farming.NextTap")}
          value={getCountdownValue()}
          onFinish={onFinish}
        />
      ) : (
        <div className={"tap-container"}>
          <Text>Taps: {knocks}</Text>
          <img
            src="/assets/bank.svg"
            alt="Bank"
            width={250}
            onClick={handleKnockBank}
            className={"tap-image"}
          />
          {showPoints && (
            <p key={animationKey} className={"points-animation"}>
              +1000
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default BankGame;
