import { FC } from "react";
import { useSelector } from "react-redux";
import { Alert } from "antd";
import Marquee from "react-fast-marquee";
import { RootState } from "../services/store";

const Banner: FC = () => {
  const { userData } = useSelector((state: RootState) => state.user);

  if (!userData || userData.settings.banner_enabled !== "on" || !userData.settings.banner_message) {
    return null;
  }

  return (
    <Alert
      banner
      type="info"
      message={
        <Marquee pauseOnHover gradient={false}>
          {userData.settings.banner_message}
        </Marquee>
      }
      style={{ marginBottom: '16px' }}
    />
  );
};

export default Banner;

