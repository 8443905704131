import { FC, useState } from "react";
import { Form, InputNumber, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { MainButton } from "@twa-dev/sdk/react";
import { sendTransaction } from "../helpers/transactionService";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { useSelector } from "react-redux";
import { RootState } from "../services/store";
import { CertificateFormValues } from "../types";

const { Title } = Typography;

const CertificatePaymentForm: FC = () => {
  const { t } = useTranslation();
  const [tonConnectUI] = useTonConnectUI();
  const { userData } = useSelector((state: RootState) => state.user);
  const { wallet_address } = useSelector((state: RootState) => state.usdt);
  const [form] = Form.useForm();
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [buttonVisible, setButtonVisible] = useState(true);

  const onValuesChange = (
    changedValues: CertificateFormValues,
    allValues: CertificateFormValues
  ) => {
    const { certificates } = allValues;
    if (certificates) {
      setTotal(certificates * 100);
    }
  };

  const handleSubmit = async (values: CertificateFormValues) => {
    setLoading(true);
    try {
      const comment = `UserId:${userData!.id};NFT-100`;
      const { certificates } = values;
      const amount = certificates * 100;
      const myTransaction = await sendTransaction(
        comment,
        "USDT",
        wallet_address,
        "EQCknME4KJMgBwhq5ZJX3P_UBt3GP8Wz7W1DzCXAGNdXutrf",
        amount
      );
      if (myTransaction) {
        const result = await tonConnectUI.sendTransaction(myTransaction);
        console.log(result);
      } else {
        console.error("Transaction could not be created.");
      }

      setLoading(false);
      setButtonVisible(false);

    } catch (error) {
      console.error("Error submitting form", error);
    }
  };

  return (
    <div style={{ padding: "20px", backgroundColor: "#fff" }}>
      <Title level={3}>{t("pay_for_certificate")}</Title>
      <Form
        form={form}
        layout="vertical"
        onValuesChange={onValuesChange}
        onFinish={handleSubmit}
      >
        <Form.Item
          name="certificates"
          label={t("number_of_certificates")}
          rules={[
            {
              required: true,
              message: t("please_enter_number_of_certificates"),
            },
          ]}
        >
          <InputNumber min={1} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item>
          <div style={{ marginBottom: "20px" }}>
            <Title level={4}>
              {t("total")}: ${total}
            </Title>
          </div>
          {buttonVisible && (
            <MainButton
              text={t("pay")}
              onClick={() => form.submit()}
              progress={loading}
            />
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default CertificatePaymentForm;
