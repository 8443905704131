import React from "react";
import { Stage, Layer, Image, Text } from "react-konva";
import useImage from "use-image";
import WebApp from "@twa-dev/sdk";
import { Flex } from "antd";
import { LoanCertificateProps } from "../types";

const LoanCertificate: React.FC<LoanCertificateProps> = ({
  loanNumber,
  loanAmount,
  loanDate,
  loanEndDate,
}) => {
  const [image] = useImage("/assets/Certificate.webp");

  return (
    <Flex>
      <Stage
        width={315}
        height={220}
        style={{ margin: "auto", display: "block", overflowY: "auto" }}
      >
        <Layer>
          <Image image={image} width={320} height={220} />
          <Text
            text={`${WebApp.initDataUnsafe.user?.first_name} ${WebApp.initDataUnsafe.user?.last_name}`}
            fontSize={8}
            x={135}
            y={65}
            fill="black"
            fontFamily="Arial"
          />
          <Text
            text={`${loanDate}`}
            fontSize={6}
            x={175}
            y={73}
            fill="black"
            fontFamily="Arial"
          />
          <Text
            text={`${loanNumber}`}
            fontSize={10}
            x={50}
            y={109}
            fill="red"
            fontFamily="Arial"
          />
          <Text
            text={`${Number(loanAmount).toFixed(0)}`}
            fontSize={16}
            x={30}
            y={183}
            fill="black"
            fontFamily="Arial"
          />
          <Text
            text={`${loanEndDate}`}
            fontSize={14}
            x={125}
            y={183}
            fill="black"
            fontFamily="Arial"
          />
          <Text
            text={`${Number(loanAmount).toFixed(0)}`}
            fontSize={16}
            x={260}
            y={183}
            fill="black"
            fontFamily="Arial"
          />
        </Layer>
      </Stage>
    </Flex>
  );
};

export default LoanCertificate;
