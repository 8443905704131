import { FC, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Col, List, Row, Tag, Tabs, Divider, Spin } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../services/store";
import WebApp from "@twa-dev/sdk";
import Post from "./Post";
import Rating from "./Rating";
import { OtherUserActionsProps, PostType } from "../types";

const OtherUserActions: FC<OtherUserActionsProps> = ({
  partner,
  isSubscribed,
  children,
  handleSubscribe,
  activeTabKey,
  onTabChange,
}) => {
  const { t } = useTranslation();
  const [posts, setPosts] = useState<PostType[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const { userData } = useSelector((state: RootState) => state.user);
  const [offset, setOffset] = useState<number>(0);
  const limit = 10;

  const fetchPosts = async () => {
    try {
      const response = await axios.get(
        `/api/account/get_posts.php?user_id=${partner.user_id}&offset=${offset}&limit=${limit}`
      );
      const newPosts = response.data.posts;

      setPosts((prevPosts) => [...prevPosts, ...newPosts]);
      setHasMore(newPosts.length === limit);
      setOffset((prevOffset) => prevOffset + limit);
    } catch (error) {
      console.error("Failed to fetch posts:", error);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <>
      <Row gutter={16}>
        {partner.username !== "" && (
          <Col span={12}>
            <Button
              type="primary"
              onClick={() =>
                WebApp.openTelegramLink(`https://t.me/${partner.username}`)
              }
            >
              {t("Message")}
            </Button>
          </Col>
        )}
        <Col span={12}>
          <Button type="primary" onClick={handleSubscribe}>
            {isSubscribed ? t("Unsubscribe") : t("Subscribe")}
          </Button>
        </Col>
      </Row>
      <Tabs
        defaultActiveKey="1"
        type="card"
        onChange={onTabChange}
        activeKey={activeTabKey}
        style={{ marginTop: 20 }}
        items={[
          {
            label: t("Posts"),
            key: "1",
            children: (
              <InfiniteScroll
                dataLength={posts.length}
                next={fetchPosts}
                hasMore={hasMore}
                loader={<Spin size="large" />}
                endMessage={<Divider />}
              >
                <List
                  dataSource={posts}
                  renderItem={(post) => (
                    <Post
                      key={post.id}
                      first_name={post.first_name}
                      last_name={post.last_name}
                      avatarUrl={post.avatar_url}
                      content={post.content}
                      imageUrl={post.image_url}
                      likes={post.likes}
                      comments_count={post.comments_count}
                      postId={post.id}
                      postTime={post.created_at}
                      user_id={post.user_id}
                      userId={userData!.id}
                      poll={post.poll}
                      liked_by_user={post.liked_by_user}
                      view_count={post.view_count}
                    />
                  )}
                />
              </InfiniteScroll>
            ),
          },
          {
            label: t("Partners"),
            key: "2",
            children: (
              <List
                dataSource={children}
                renderItem={(child) => (
                  <List.Item key={child.user_id}>
                    <List.Item.Meta
                      title={
                        <div className="history-item">
                          <Link
                            to={`/partners/${child.user_id}`}
                          >{`${child.first_name} ${child.last_name}`}</Link>
                        </div>
                      }
                    />
                    {child.active ? (
                      <Tag color="green">{t("active")}</Tag>
                    ) : (
                      <Tag color="orange">{t("not active")}</Tag>
                    )}
                  </List.Item>
                )}
              />
            ),
          },
          {
            label: t("Score"),
            key: "3",
            children: <Rating rating={partner.rating} />,
          },
        ]}
      />
    </>
  );
};

export default OtherUserActions;
