import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import WebApp from "@twa-dev/sdk";
import { useSelector } from 'react-redux';
import { RootState } from '../services/store';
import {
  Statistic,
  Card,
  Typography,
  Button,
  Modal,
  List,
  Pagination,
  message,
} from "antd";
import CountUp from "react-countup";
import { RightCircleTwoTone, QuestionCircleTwoTone } from "@ant-design/icons";
import { TransactionTokens } from "../types";
import type { StatisticProps } from "antd";
import { getTransactions } from "../helpers/api";

const formatter: StatisticProps["formatter"] = (value) => (
  <CountUp end={value as number} separator=" " />
);

const { Paragraph, Title } = Typography;

const WebTokens: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userData } = useSelector((state: RootState) => state.user);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalTransactions, setTotalTransactions] = useState(0);

  useEffect(() => {
    WebApp.BackButton.show();
    WebApp.BackButton.onClick(() => window.history.back());

    return () => {
      WebApp.BackButton.hide();
    };
  }, []);

  useEffect(() => {
    const fetchTransactions = async (page: number, pageSize: number) => {
      try {
        const data = await getTransactions(page, pageSize);
        setTransactions(data.transactions);
        setTotalTransactions(data.total);
      } catch (error) {
        message.error(t("transactionFetchError"));
        console.error("Error fetching transactions:", error);
      }
    };

    fetchTransactions(currentPage, pageSize);
  }, [currentPage, pageSize, userData, t]);

  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) {
      setPageSize(pageSize);
    }
  };

  return (
    <div>
      <Button
        type="link"
        icon={<QuestionCircleTwoTone />}
        onClick={() => setIsModalVisible(true)}
        style={{ float: "right" }}
      />
      <Title level={3}>{t("Credits")}</Title>
      <div
        style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}
      ></div>

      <Card>
        <Statistic
          title={t("real balance")}
          precision={0}
          value={userData!.web_tokens ?? 0}
          formatter={formatter}
        />
      </Card>
      <Card
        hoverable
        className="buttons"
        onClick={() => navigate("/web-tokens/tasks")}
        style={{ marginTop: 16 }}
      >
        <div className="card">
          <Typography.Text strong>{t("tasksTitle")}</Typography.Text>
          <RightCircleTwoTone style={{ fontSize: "24px" }} />
        </div>
      </Card>

      <Card title={t("transaction history")} style={{ marginTop: 16 }}>
        <List
          itemLayout="horizontal"
          dataSource={transactions}
          renderItem={(transaction: TransactionTokens) => (
            <List.Item>
              <List.Item.Meta
                title={t(transaction.transaction_type)}
                description={new Date(transaction.created_at).toLocaleString()}
              />
              <div>{transaction.amount} $WEB</div>
            </List.Item>
          )}
        />
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={totalTransactions}
          onChange={handlePageChange}
          style={{ marginTop: 16, textAlign: "center" }}
        />
      </Card>

      <Modal
        title={t("FAQ")}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Paragraph>{t("Credits info")}</Paragraph>
      </Modal>
    </div>
  );
};

export default WebTokens;
