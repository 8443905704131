import "./polifill";

import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import "./index.css";
import "@telegram-apps/telegram-ui/dist/styles.css";
import { AppRoot } from "@telegram-apps/telegram-ui";
import App from "./App";
import { THEME, TonConnectUIProvider } from "@tonconnect/ui-react";
import store from "./services/store";
import ErrorBoundary from "./helpers/ErrorBoundary";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AppRoot>
      <TonConnectUIProvider
        manifestUrl="https://webtransfer.app/tonconnect-manifest.json"
        uiPreferences={{ theme: THEME.DARK }}
        walletsListConfiguration={{
          includeWallets: [
            {
              appName: "safepalwallet",
              name: "SafePal",
              imageUrl:
                "https://s.pvcliping.com/web/public_image/SafePal_x288.png",
              tondns: "",
              aboutUrl: "https://www.safepal.com",
              universalLink: "https://link.safepal.io/ton-connect",
              deepLink: "safepal-tc://",
              jsBridgeKey: "safepalwallet",
              bridgeUrl: "https://ton-bridge.safepal.com/tonbridge/v1/bridge",
              platforms: ["ios", "android", "chrome", "firefox"],
            },
            {
              appName: "bitgetTonWallet",
              name: "Bitget Wallet",
              imageUrl:
                "https://raw.githubusercontent.com/bitkeepwallet/download/main/logo/png/bitget%20wallet_logo_iOS.png",
              aboutUrl: "https://web3.bitget.com",
              deepLink: "bitkeep://",
              jsBridgeKey: "bitgetTonWallet",
              bridgeUrl: "https://bridge.tonapi.io/bridge",
              platforms: ["ios", "android", "chrome"],
              universalLink: "https://bkcode.vip/ton-connect",
            },
            {
              appName: "tonwallet",
              name: "TON Wallet",
              imageUrl: "https://wallet.ton.org/assets/ui/qr-logo.png",
              aboutUrl:
                "https://chrome.google.com/webstore/detail/ton-wallet/nphplpgoakhhjchkkhmiggakijnkhfnd",
              universalLink: "https://wallet.ton.org/ton-connect",
              jsBridgeKey: "tonwallet",
              bridgeUrl: "https://bridge.tonapi.io/bridge",
              platforms: ["chrome", "android"],
            },
          ],
        }}
        actionsConfiguration={{
          twaReturnUrl: "https://t.me/Webtransfer_apps_bot/webtransfer",
        }}
      >
        <Provider store={store}>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </Provider>
      </TonConnectUIProvider>
    </AppRoot>
  </React.StrictMode>
);
