import { FC } from "react";
import { Row, Col, Card, Typography } from "antd";
import { useTranslation } from "react-i18next";
import {
  BarChartOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";

const { Title, Text } = Typography;

const NonPremiumContent: FC = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{ padding: "20px", background: "#f9f9f9", borderRadius: "8px" }}
    >
      <Title level={3}>{t("Premium Subscription")}</Title>
      <Text style={{ fontSize: "14px" }}>
        {t(
          "Unlock premium features with our subscription! Enjoy exclusive benefits including:"
        )}
      </Text>

      <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
        <Col xs={24} md={12}>
          <Card>
            <UsergroupAddOutlined
              style={{ fontSize: "24px", color: "#52c41a" }}
            />
            <Text style={{ marginLeft: "10px", fontSize: "16px" }}>
              {t("Expanded Social Network Features")}
            </Text>
            <p style={{ marginTop: "10px" }}>
              {t(
                "Gain access to advanced social networking tools, including enhanced profiles, connections, and interactions."
              )}
            </p>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card>
            <BarChartOutlined style={{ fontSize: "24px", color: "#faad14" }} />
            <Text style={{ marginLeft: "10px", fontSize: "16px" }}>
              {t("Advanced Referral Structure Analysis")}
            </Text>
            <p style={{ marginTop: "10px" }}>
              {t(
                "Gain insights with in-depth analysis of your referral network's structure and performance."
              )}
            </p>
          </Card>
        </Col>
      </Row>
      <Text style={{ marginTop: "20px", display: "block", fontSize: "16px" }}>
        {t("Price")}: 1 TON / {t("month")}
      </Text>
    </div>
  );
};

export default NonPremiumContent;
