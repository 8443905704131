import { FC, useState } from "react";
import { MyLoansProps, Loan } from "../types";
import { List, Tag, Card, Modal, Button, message } from "antd";
import { getRateColor } from "../helpers/functions";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toNano, beginCell } from "@ton/ton";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { RootState } from "../services/store";
import { cancelLoan } from "../helpers/api";
import CryptoJS from "crypto-js";

const MyLoans: FC<MyLoansProps> = ({ loans }) => {
  const { t } = useTranslation();
  const [tonConnectUI] = useTonConnectUI();
  const { userData } = useSelector((state: RootState) => state.user);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedLoan, setSelectedLoan] = useState<Loan | null>(null);

  const handlePayOff = (loan: Loan) => {
    setSelectedLoan(loan);
    setModalVisible(true);
  };

  const handleModalOk = async () => {
    if (selectedLoan) {
      try {
        const { loan_id } = selectedLoan;
        const comment = `loan_id:${loan_id}`;
        const secretKey = CryptoJS.SHA256("webtransfer").toString(
          CryptoJS.enc.Hex
        );
        const key = CryptoJS.enc.Hex.parse(secretKey);
        const iv = CryptoJS.lib.WordArray.random(16);

        const encrypted = CryptoJS.AES.encrypt(comment, key, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        });
        const encryptedComment = iv
          .concat(encrypted.ciphertext)
          .toString(CryptoJS.enc.Base64);

        const body = beginCell()
          .storeUint(0, 32)
          .storeStringTail(encryptedComment.toString())
          .endCell();

        const myTransaction = {
          validUntil: Math.floor(Date.now() / 1000) + 60,
          messages: [
            {
              address: userData!.settings.pay_wallet,
              amount: toNano(selectedLoan.repayment_amount).toString(),
              payload: body.toBoc().toString("base64"),
            },
          ],
        };
        const result = await tonConnectUI.sendTransaction(myTransaction);
        console.log(result);
      } catch (error) {
        console.error("Error repaying loan", error);
        message.error(t("Error repaying loan"));
      }
    }
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const handleCancelLoan = async (loanId: number) => {
    try {
      const response = await cancelLoan(loanId);
      if (response.status === "success") {
        message.success(t("Loan cancelled successfully"));
        window.location.reload();
      } else {
        message.error(t("Failed to cancel loan"));
      }
    } catch (error) {
      message.error(t("Error cancelling loan"));
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "issued":
        return "green";
      case "pending":
        return "orange";
      default:
        return "gray";
    }
  };

  return (
    <Card title={t("My loans")} style={{ marginTop: 16 }}>
      <List
        itemLayout="horizontal"
        dataSource={loans}
        renderItem={(loan) => (
          <List.Item key={loan.id}>
            <List.Item.Meta
              title={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="./assets/tonIcon.png"
                    alt="Logo"
                    width={20}
                    style={{ marginRight: 8 }}
                  />
                  {loan.amount}
                </div>
              }
              description={
                <div>
                  <span>
                    {loan.term} {t("days")} -{" "}
                  </span>
                  <Tag color={getStatusColor(loan.status)}>
                    {t(`loan_status.${loan.status}`)}
                  </Tag>
                </div>
              }
            />
            <Tag color={getRateColor(loan.rate)}>{loan.rate}%</Tag>
            {loan.status === "pending" && (
              <Button onClick={() => handleCancelLoan(loan.loan_id)}>
                {t("Cancel")}
              </Button>
            )}
            {loan.status === "issued" && (
              <Button onClick={() => handlePayOff(loan)}>{t("Pay off")}</Button>
            )}
          </List.Item>
        )}
      />
      <Modal
        title={t("Pay off loan")}
        open={modalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <p>{t("Are you sure you want to pay off the loan?")}</p>
      </Modal>
    </Card>
  );
};

export default MyLoans;
