import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Spin, Typography, Modal, Tag } from "antd";
import WebApp from "@twa-dev/sdk";
import { getRateColor } from "../helpers/functions";
import { useTranslation } from "react-i18next";
import { MainButton } from "@twa-dev/sdk/react";
import { fetchLoansData } from "../helpers/api";
import { Loan } from "../types";

const { Title, Text } = Typography;

const LoanExchange: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loans, setLoans] = useState<Loan[]>([]);
  const [loansIssued, setLoansIssued] = useState<Loan[]>([]);
  const [loadingLoans, setLoadingLoans] = useState<boolean>(true);
  const [selectedLoan, setSelectedLoan] = useState<Loan | null>(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [buttonConfig, setButtonConfig] = useState<{ text: string; path: string }>({ text: "", path: "" });

  useEffect(() => {
    WebApp.BackButton.show();
    WebApp.BackButton.onClick(() => window.history.back());

    return () => {
      WebApp.BackButton.hide();
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchLoansData();
        setLoansIssued(response.loans_issued);
        setLoans(response.loans);
      } catch (error) {
        console.error("Error fetching loans data:", error);
      } finally {
        setLoadingLoans(false);
      }
    };

    fetchData();
  }, []);

  const showModal = (loan: Loan, fromIssuedLoans: boolean) => {
    setSelectedLoan(loan);
    setIsModalVisible(true);

    if (fromIssuedLoans) {
      setButtonConfig({ text: t("Take out a loan"), path: "/loan-insure" });
    } else {
      setButtonConfig({ text: t("to issue a loan"), path: "/loan-issuance" });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedLoan(null);
  };

  const handleSubmit = () => {
    if (buttonConfig.path) {
      navigate(buttonConfig.path);
    }
  };

  if (loadingLoans) {
    return <Spin tip="Loading..." />;
  }

  return (
    <div className="loan-exchange-container">
      <Title level={2}>{t("Loan Exchange")}</Title>
      <Row gutter={16}>
        <Col span={12}>
          <Row gutter={[6, 6]} className="border">
          <Title level={5}>{t("Выдают")}</Title>
            {loansIssued.map((loan) => (
              <Col span={24} key={loan.loan_id}>
                <div
                  className="exchange-card"
                  onClick={() => showModal(loan, true)}
                >
                  <Text style={{ display: "block" }}>
                    {loan.amount} {loan.currency === "USDT" ? "USDT" : "TON"}
                  </Text>
                  <Tag color={getRateColor(loan.rate)}>{loan.rate}%</Tag>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={[6, 6]}>
          <Title level={5}>{t("Хотят взять")}</Title>
            {loans.map((loan) => (
              <Col span={24} key={loan.loan_id}>
                <div
                  className="exchange-card"
                  onClick={() => showModal(loan, false)}
                >
                  <Text style={{ display: "block" }}>
                    {loan.amount} {loan.currency === "USDT" ? "USDT" : "TON"}
                  </Text>
                  <Tag color={getRateColor(loan.rate)}>{loan.rate}%</Tag>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>

      {selectedLoan && (
        <>
          <Modal
            title={t("Loan Details")}
            open={isModalVisible}
            onCancel={handleCancel}
            footer={null}
          >
            <p>
              {t("Amount")}: {selectedLoan.amount}{" "}
              {selectedLoan.currency === "USDT" ? "USDT" : "TON"}
            </p>
            <p>{t("Rate")}: {selectedLoan.rate}%</p>
            <p>{t("Term")}: {selectedLoan.term} {t("days")}</p>
            <p>
              {t("To be refunded")}: {selectedLoan.total_repayment ? selectedLoan.total_repayment : selectedLoan.total_amount}{" "}
              {selectedLoan.currency === "USDT" ? "USDT" : "TON"}
            </p>
          </Modal>
          <MainButton text={buttonConfig.text} onClick={handleSubmit} />
        </>
      )}
    </div>
  );
};

export default LoanExchange;
