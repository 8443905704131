import { FC, useState } from "react";
import { Avatar, Button, Upload, message, Flex } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../services/store";

interface AvatarUploadProps {
  userId: number;
  avatarUrl: string | null;
  onUpdate: (newAvatarUrl: string) => void;
  modal: boolean;
}

const AvatarUpload: FC<AvatarUploadProps> = ({ userId, avatarUrl, onUpdate, modal }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { userData } = useSelector((state: RootState) => state.user);
  const handleAvatarChange = async (file: any) => {
    const reader = new FileReader();
    reader.onload = async () => {
      const imageUrl = reader.result as string;
      setLoading(true);

      try {
        await axios.post("/api/account/upload_avatar.php", {
          image_url: imageUrl,
        });
        message.success(t("Avatar updated successfully!"));

        // Обновляем avatarUrl после успешной загрузки
        const response = await axios.post("/api/partners/get-partner-details.php", {
          user_id: userId,
        });
        onUpdate(response.data.partner.avatar_url);
      } catch (error) {
        message.error(t("Failed to update avatar"));
        console.error("Failed to upload avatar:", error);
      } finally {
        setLoading(false);
      }
    };
    reader.readAsDataURL(file);
  };

  const uploadProps = {
    beforeUpload: (file: any) => {
      handleAvatarChange(file);
      return false;
    },
    showUploadList: false,
  };

  return (
    <Flex>
      {avatarUrl && <Avatar src={avatarUrl} size={64} shape="square" />}
      {(!avatarUrl || modal) && userData?.id === Number(userId) && (
        <Upload {...uploadProps} listType="picture-card" maxCount={1} accept="image/*">
          <Button icon={<UploadOutlined />} loading={loading} />
        </Upload>
      )}
    </Flex>
  );
};

export default AvatarUpload;
