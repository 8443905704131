import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { List, Typography, Pagination, Spin, Row, Col, Modal, Tag } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../services/store";
import { determineTransactionDetails } from "../helpers/functions";
import { fetchUserTransactions } from "../helpers/api";
import { Transaction } from "../types";

const { Text, Title } = Typography;

const TransactionHistory: React.FC = () => {
  const { t } = useTranslation();
  const { userData } = useSelector((state: RootState) => state.user);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [selectedTransaction, setSelectedTransaction] =
    useState<Transaction | null>(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const itemsPerPage = 10;

  useEffect(() => {
    if (!userData) return;
    setLoading(true);

    fetchUserTransactions(page, itemsPerPage)
      .then((data) => {
        setTransactions(data.transactions);
        setTotalPages(data.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user transactions:", error);
        setLoading(false);
      });
  }, [userData, page]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const showModal = (transaction: Transaction) => {
    setSelectedTransaction(transaction);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedTransaction(null);
  };

  return (
    <div>
      <Title level={4}>{t("transaction history")}</Title>
      {loading ? (
        <Spin size="large" />
      ) : (
        <List
          itemLayout="horizontal"
          dataSource={transactions}
          renderItem={(transaction) => {
            const { displayAmount, color } =
              determineTransactionDetails(transaction);
            return (
              <List.Item
                onClick={() => showModal(transaction)}
                style={{ cursor: "pointer" }}
              >
                <List.Item.Meta
                  title={
                    <div className="history-item">
                      <Text>
                        {t(`transaction_type.${transaction.transaction_type}`)}
                      </Text>
                      <Text
                        type="secondary"
                        style={{ fontSize: 12, fontWeight: "normal" }}
                      >
                        {new Date(
                          transaction.transaction_date
                        ).toLocaleString()}
                      </Text>
                    </div>
                  }
                />
                <div className="history-item-price">
                  <Text strong style={{ color }}>
                    {transaction.transaction_type === "referral_bonus" ||
                    transaction.bonus ? (
                      `${displayAmount}$`
                    ) : (
                      <>
                        {displayAmount}
                        <img
                          src="./assets/tonIcon.png"
                          alt="Logo"
                          width={12}
                          style={{ marginLeft: 5 }}
                        />
                      </>
                    )}
                  </Text>
                  {transaction.bonus === 1 && (
                    <Tag color="green" style={{ marginLeft: 10 }}>
                      {t("bonus")}
                    </Tag>
                  )}
                </div>
              </List.Item>
            );
          }}
          locale={{ emptyText: t("No transactions yet") }}
        />
      )}
      <Row justify="center" style={{ marginTop: 20 }}>
        <Col>
          <Pagination
            current={page}
            total={totalPages * itemsPerPage}
            pageSize={itemsPerPage}
            onChange={handlePageChange}
            showSizeChanger={false}
          />
        </Col>
      </Row>

      <Modal
        title={t("Transaction Details")}
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        {selectedTransaction && (
          <div>
            <p>
              <strong>{t("Amount")}:</strong>
              {selectedTransaction.transaction_type === "referral_bonus" ||
              selectedTransaction.bonus ? (
                <Text
                  strong
                  style={{
                    color:
                      determineTransactionDetails(selectedTransaction).color,
                    marginLeft: 5,
                  }}
                >
                  {
                    determineTransactionDetails(selectedTransaction)
                      .displayAmount
                  }
                  $
                </Text>
              ) : (
                <>
                  <Text
                    strong
                    style={{
                      color:
                        determineTransactionDetails(selectedTransaction).color,
                      marginLeft: 5,
                      marginRight: 5,
                    }}
                  >
                    {
                      determineTransactionDetails(selectedTransaction)
                        .displayAmount
                    }
                  </Text>
                  <img
                    src="./assets/tonIcon.png"
                    alt="Logo"
                    width={12}
                    style={{ marginRight: 5 }}
                  />
                </>
              )}
            </p>
            <p>
              <strong>{t("Type")}:</strong>{" "}
              {t(`transaction_type.${selectedTransaction.transaction_type}`)}
            </p>
            <p>
              <strong>{t("Date")}:</strong>{" "}
              {selectedTransaction.transaction_date.substring(0, 10)}
            </p>

            {selectedTransaction.description && (
              <p>
                <strong>{t("Description")}:</strong>{" "}
                {selectedTransaction.description}
              </p>
            )}
            {selectedTransaction.referral_username && (
              <p>
                <strong>{t("For")}:</strong>{" "}
                <a
                  href={`https://t.me/${selectedTransaction.referral_username}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  @{selectedTransaction.referral_username}
                </a>
              </p>
            )}
            {selectedTransaction.bonus === 1 && (
              <p>
                <Tag color="green">{t("bonus")}</Tag>
              </p>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default TransactionHistory;
