import { useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { RootState } from "../services/store";

const useAxiosSetup = () => {
  const { userData } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        if (userData && userData.token) {
          config.headers.Authorization = `Bearer ${userData.token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // Cleanup function to remove interceptors when the component is unmounted
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
    };
  }, [userData]);
};

export default useAxiosSetup;
