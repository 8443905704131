import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Space, Card } from "antd";
import WebApp from "@twa-dev/sdk";
import { RightCircleTwoTone } from "@ant-design/icons";

const { Title } = Typography;

const About: React.FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    WebApp.BackButton.show();
    WebApp.BackButton.onClick(() => window.history.back());

    return () => {
      WebApp.BackButton.hide();
    };
  }, []);

  const handleCardClick = (url: string) => {
    WebApp.openLink(url);
  };

  return (
    <div style={{ padding: "20px", backgroundColor: "#fff" }}>
      <Title level={3}>{t("about_title")}</Title>
      <Space direction="vertical" style={{ width: "100%" }}>
      <Card
          hoverable
          className="buttons"
          onClick={() =>
            handleCardClick("https://webtransfer.com/about_webtransfer")
          }
        >
          <div className="card">
            <Typography.Text strong>{t("About")}</Typography.Text>
            <RightCircleTwoTone style={{ fontSize: "24px" }} />
          </div>
        </Card>
        <Card
          hoverable
          className="buttons"
          onClick={() =>
            handleCardClick("https://webtransfer.com/loan-rules-and-conditions")
          }
        >
          <div className="card">
            <Typography.Text strong>{t("Rules")}</Typography.Text>
            <RightCircleTwoTone style={{ fontSize: "24px" }} />
          </div>
        </Card>
        <Card
          hoverable
          className="buttons"
          onClick={() =>
            handleCardClick("https://webtransfer.com/risk-warning")
          }
        >
          <div className="card">
            <Typography.Text strong>{t("risks")}</Typography.Text>
            <RightCircleTwoTone style={{ fontSize: "24px" }} />
          </div>
        </Card>

        <Card
          hoverable
          className="buttons"
          onClick={() =>
            handleCardClick("https://webtransfer.com/privacy-policy")
          }
        >
          <div className="card">
            <Typography.Text strong>{t("privacy-policy")}</Typography.Text>
            <RightCircleTwoTone style={{ fontSize: "24px" }} />
          </div>
        </Card>
      </Space>
    </div>
  );
};

export default About;
