import { FC, useState, useEffect } from "react";
import { Typography, List, Divider, Spin } from "antd";
import { useTranslation } from "react-i18next";
import axios from "axios";
import {PurchaseHistoryItem} from '../types';

const { Title, Text } = Typography;


const PremiumContent: FC = () => {
  const { t } = useTranslation();
  const [premiumEndDate, setPremiumEndDate] = useState<string | null>(null);
  const [purchaseHistory, setPurchaseHistory] = useState<PurchaseHistoryItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchPremiumData = async () => {
      try {
        const response = await axios.post("/api/premium-data.php");
        const data = response.data;

        setPremiumEndDate(data.premiumEndDate);
        setPurchaseHistory(data.purchaseHistory);
      } catch (error) {
        console.error("Error fetching premium data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPremiumData();
  }, []);

  if (loading) {
    return <Spin />;
  }

  return (
    <div>
      <Title level={3} style={{ color: "#1890ff" }}>
        {t("welcome_to_premium")}
      </Title>

      <Text style={{ fontSize: "16px", display: "block", marginTop: "10px" }}>
        {t("premium_ends_on")}: <strong>{premiumEndDate || t("no_premium_subscription")}</strong>
      </Text>

      <Divider />

      <Title level={4}>{t("purchase_history")}</Title>
      <List
        itemLayout="horizontal"
        dataSource={purchaseHistory}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              title={`${Number(item.amount).toFixed(0)} TON`}
              description={item.date}
            />
          </List.Item>
        )}
      />
    </div>
  );
};

export default PremiumContent;
