import { FC } from 'react';
import Poll from 'react-polls';
import { PollQuestion, PollOption } from '../types';

const convertToPollAnswers = (options: PollOption[]) => {
  return options.map(option => ({
    option: option.option_text,
    votes: option.vote_count || 0
  }));
};

interface PollProps {
  poll: PollQuestion;
  onVote: (voteAnswer: string) => void;
}

const pollStyles2 = {
  questionSeparator: false,
  questionSeparatorWidth: 'question',
  questionBold: false ,
  questionColor: '#4F70D6',
  align: 'center',
  theme: 'blue'
}

const PollComponent: FC<PollProps> = ({ poll, onVote }) => {
  const handleVote = (voteAnswer: string) => {
    console.log({voteAnswer})
    onVote(voteAnswer);
  };

  return (
    <Poll
      question={poll.question}
      answers={convertToPollAnswers(poll.options)}
      onVote={handleVote}
      customStyles={pollStyles2}
    />
  );
};

export default PollComponent;
