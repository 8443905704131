import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Typography } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../services/store";

const { Title } = Typography;

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userData } = useSelector((state: RootState) => state.user);
  const mode = useSelector((state: RootState) => state.app.mode);

  const navigateTo = (path: string) => {
    navigate(path);
  };

  const cardItems = [
    ...(mode === "Full"
      ? [
          {
            path: `/partners/${userData?.id}`,
            title: t("My profile"),
          },
          {
            path: "/feed",
            title: t("Feed"),
          },
        ]
      : []),
    {
      path: "/loan-issuance",
      title: t("to issue a loan"),
    },
    {
      path: "/loan-insure",
      title: t("Take out a loan"),
    },
    ...(mode === "Full"
      ? [
          {
            path: "/invest",
            title: t("Invest in Loans Wholesale"),
          },
          {
            path: "/premium",
            title: t("Premium"),
          },
        ]
      : []),
    {
      path: "/partners",
      title: t("partners network"),
    },
    ...(mode === "Full"
      ? [
          {
            path: "/bank-game",
            title: t("Farming.Title"),
          },
          {
            path: "/web-tokens",
            title: t("web credits"),
          },
        ]
      : []),

    {
      path: "/withdraw",
      title: t("Withdrawal"),
    },
  ];

  return (
    <div className="dashboard">
      <Title level={4}>{t("control panel")}</Title>
      {cardItems.map((item) => (
        <div
          className="tabs_panel"
          onClick={() => navigateTo(item.path)}
          key={item.title}
        >
          {item.title}
        </div>
      ))}
    </div>
  );
};

export default Dashboard;
