import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserResponse } from "../../types";

interface UserState {
  userData: UserResponse | null;
  isLoading: boolean;
  startParam: string;
}

const initialState: UserState = {
  userData: null,
  isLoading: true,
  startParam: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<UserResponse>) => {
      state.userData = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    updateWebTokens: (state, action: PayloadAction<number>) => {
      if (state.userData) {
        state.userData.web_tokens += action.payload;
      }
    },
    updateBonusBalance: (state, action: PayloadAction<number>) => {
      if (state.userData) {
        const updatedBalance = (state.userData.bonus_balance + action.payload).toFixed(2);
        state.userData.bonus_balance = parseFloat(updatedBalance);
      }
    },
    updatePartnerBalance: (state, action: PayloadAction<number>) => {
      if (state.userData) {
        state.userData.partner_balance =
      +(state.userData.partner_balance - action.payload).toFixed(2);
      }
    },
    setStartParam(state, action: PayloadAction<string>) {
      state.startParam = action.payload;
    },
    updateUnreadNotifications: (state, action: PayloadAction<number>) => {
      if (state.userData) {
        state.userData.unread_notifications = action.payload;
      }
    },
  },
});

export const { setUserData, setLoading, updateWebTokens, updateBonusBalance, updatePartnerBalance, setStartParam, updateUnreadNotifications } = userSlice.actions;
export default userSlice.reducer;


