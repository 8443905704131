import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AppState {
  mode: 'Basic' | 'Full';
}

const initialState: AppState = {
  mode: localStorage.getItem('app_mode') === 'Full' ? 'Full' : 'Basic',
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setMode: (state, action: PayloadAction<'Basic' | 'Full'>) => {
      state.mode = action.payload;
      localStorage.setItem('app_mode', action.payload);
    },
  },
});

export const { setMode } = appSlice.actions;
export default appSlice.reducer;
