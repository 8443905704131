import { FC, useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  Card,
  Avatar,
  Typography,
  Image,
  Space,
  message,
  Modal,
  Input,
  Button,
  Dropdown,
} from "antd";
import {
  EyeIcon,
  HandThumbUpIcon,
  TrashIcon,
  InformationCircleIcon,
  PencilIcon,
  ChatBubbleOvalLeftEllipsisIcon
} from "@heroicons/react/24/solid";
import { EllipsisOutlined } from "@ant-design/icons";
import moment from "moment";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { PostProps } from "../types";
import { useNavigate } from "react-router-dom";
import PollComponent from "./Poll";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm';
import { formatViewCount } from "../helpers/functions";
import { updateBonusBalance } from "../services/slices/userSlice";
import { AppDispatch } from "../services/store";
import type { MenuProps } from "antd";

const { Paragraph, Text } = Typography;
const { Meta } = Card;

const Post: FC<PostProps> = ({
  first_name,
  last_name,
  avatarUrl,
  content,
  imageUrl,
  likes,
  comments_count,
  postId,
  postTime,
  user_id,
  userId,
  poll,
  liked_by_user,
  view_count,
  onDelete,
}) => {
  const [likeCount, setLikeCount] = useState(likes);
  const [liked, setLiked] = useState(liked_by_user);
  const [viewCount, setViewCount] = useState<number>(view_count);
  const [isEditing, setIsEditing] = useState(false);
  const [editContent, setEditContent] = useState(content);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const postRef = useRef<HTMLDivElement>(null);
  const hasIncremented = useRef(false);

  useEffect(() => {
    const incrementViewCount = async () => {
      try {
        await axios.post("/api/account/increment_post_view.php", {
          post_id: postId,
        });
        hasIncremented.current = true;
        setViewCount(viewCount + 1);
      } catch (error) {
        console.error("Failed to increment view count:", error);
      }
    };

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !hasIncremented.current) {
            incrementViewCount();
          }
        });
      },
      { threshold: 0.5 }
    );

    if (postRef.current) {
      observer.observe(postRef.current);
    }

    return () => {
      if (postRef.current) {
        observer.unobserve(postRef.current);
      }
    };
  }, [postId, viewCount]);

  const handleDelete = async (postId: number) => {
    Modal.confirm({
      title: t("Are you sure you want to delete this post?"),
      content: t("Once deleted, you will not be able to recover this post!"),
      okText: t("Delete"),
      okType: "danger",
      cancelText: t("Cancel"),
      onOk: async () => {
        try {
          await axios.post("/api/account/delete_post.php", {
            post_id: postId,
          });

          if (onDelete) {
            onDelete(postId);
          }

          message.success(t("The post was successfully deleted!"));
        } catch (error) {
          console.error("Failed to delete post:", error);
          message.error(t("Failed to delete post"));
        }
      },
    });
  };

  const handleEdit = () => {
    setIsEditing(true); // Enable editing mode
  };

  // Функция для сохранения отредактированного поста
  const handleSaveEdit = async () => {
    try {
      const response = await axios.post("/api/account/edit_post.php", {
        post_id: postId,
        content: editContent,
      });

      if (response.data.success) {
        message.success(t("Post successfully updated"));
        setIsEditing(false);
      } else {
        message.error(t("Failed to update post"));
      }
    } catch (error) {
      console.error("Error updating post:", error);
      message.error(t("Error updating post"));
    }
  };

  const handleLike = async () => {
    try {
      const response = await axios.post("/api/account/like_post.php", {
        user_id: userId,
        post_id: postId,
      });
      if (response.data.action === "liked") {
        setLikeCount(likeCount + 1);
        setLiked(true);
        dispatch(updateBonusBalance(response.data.bonus_added));
      } else {
        setLikeCount(likeCount - 1);
        setLiked(false);
      }
    } catch (error) {
      console.error("Failed to like/unlike post:", error);
    }
  };

  const handleReport = async (postId: number) => {
    try {
      await axios.post("/api/account/report_post.php", {
        user_id: userId,
        post_id: postId,
      });
      message.success(t("Report has been send"));
    } catch (error) {
      console.error("Failed to report post:", error);
    }
  };

  const handleVote = async (voteAnswer: string) => {
    if (voteAnswer === null) {
      return;
    }
    try {
      await axios.post("/api/account/vote_poll.php", {
        user_id: userId,
        post_id: postId,
        option_index: voteAnswer,
      });
      message.success(t("Your vote has been submitted"));
    } catch (error) {
      console.error("Failed to submit vote:", error);
      message.error(t("Failed to submit vote"));
    }
  };

  // Меню для владельца поста
  const ownerMenu: MenuProps = {
    items: [
      {
        key: "edit",
        label: <Text onClick={handleEdit}>{t("Edit")}</Text>,
        icon: <PencilIcon width={16} />,
      },
      {
        key: "delete",
        label: <Text onClick={() => handleDelete(postId)}>{t("Delete")}</Text>,
        icon: <TrashIcon style={{ color: "red" }} width={16} />,
      },
    ],
  };

  // Меню для других пользователей
  const reportMenu: MenuProps = {
    items: [
      {
        key: "report",
        label: <Text onClick={() => handleReport(postId)}>{t("Report")}</Text>,
        icon: <InformationCircleIcon width={16} />,
      },
    ],
  };

  return (
    <div ref={postRef}>
      <Card
        style={{ marginBottom: 16, borderRadius: 8, alignItems: "start" }}
        actions={[
          <Space onClick={handleLike} key="like">
            <HandThumbUpIcon
              style={{ color: liked ? "red" : "inherit", display: "flex" }}
              width={16}
            />
            <Text>{formatViewCount(likeCount)}</Text>
          </Space>,
          <Space key="comments" onClick={() => navigate(`/post/${postId}`)}>
            <ChatBubbleOvalLeftEllipsisIcon width={14} style={{display: "flex"}}/>
            <Text>{formatViewCount(comments_count)}</Text>
          </Space>,
          <Dropdown
            menu={user_id === userId ? ownerMenu : reportMenu}
            key="more"
          >
            <Space>
              <EllipsisOutlined />
            </Space>
          </Dropdown>,
        ]}
        cover={imageUrl && <Image src={imageUrl} alt="Post image" />}
      >
        {isEditing ? (
          <div>
            <Input.TextArea
              value={editContent}
              onChange={(e) => setEditContent(e.target.value)}
              rows={4}
            />
            <Button
              onClick={handleSaveEdit}
              type="primary"
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              {t("Save")}
            </Button>
            <Button
              onClick={() => setIsEditing(false)}
              style={{ marginTop: 10, marginLeft: 8 }}
            >
              {t("Cancel")}
            </Button>
          </div>
        ) : (
          <Paragraph className="post_content">
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
              a: ({ node, ...props }) => (
                <a href={props.href} target="_blank" rel="noopener noreferrer">
                  {props.children}
                </a>
              ),
            }}
          >
            {editContent}
          </ReactMarkdown>
        </Paragraph>
        )}

        {poll && poll.options && poll.options.length > 0 && (
          <PollComponent poll={poll} onVote={handleVote} />
        )}

        <Meta
          avatar={
            <Avatar
              src={avatarUrl}
              size="large"
              shape="square"
              onClick={() => navigate(`/partners/${user_id}`)}
            />
          }
          title={
            <div
              className="history-item"
              onClick={() => navigate(`/post/${postId}`)}
            >
              <Text strong>
                {first_name} {last_name}
              </Text>
              <div className="post_bottom">
                <span>{moment.utc(postTime).fromNow()}</span>
                <div className="post_count">
                <EyeIcon width={14}/>
                <span>{formatViewCount(viewCount)}</span>
                </div>
              </div>
            </div>
          }
        />
      </Card>
    </div>
  );
};

export default Post;
