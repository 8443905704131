import { FC, useEffect } from "react";
import WebApp from "@twa-dev/sdk";
import CertificatePaymentForm from "../components/CertificatePaymentForm";


const InvestPayment: FC = () => {
  useEffect(() => {
    WebApp.BackButton.show();
    WebApp.BackButton.onClick(() => window.history.back());

    return () => {
      WebApp.BackButton.hide();
    };
  }, []);

  return <CertificatePaymentForm />;
};

export default InvestPayment;
