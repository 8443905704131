import { configureStore, Middleware, isPlainObject } from '@reduxjs/toolkit';
import appReducer from './slices/appSlice';
import userReducer from './slices/userSlice';
import tonReducer from './slices/tonSlice';
import usdtReducer from './slices/usdtSlice';
import bankReducer from './reducers';

const loggerMiddleware: Middleware = (storeAPI) => (next) => (action) => {
  try {
    const result = next(action);
    console.log('Middleware - NEW STATE: ', storeAPI.getState());
    return result;
  } catch (error) {
    console.error('Error in middleware:', error);
    throw error;
  }
};

const isSerializable = (value: any) => {
  return typeof value === 'string' || isPlainObject(value) || Array.isArray(value);
};

const store = configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    ton: tonReducer,
    usdt: usdtReducer,
    game: bankReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        isSerializable,
      },
    }).concat(loggerMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
