import { Component, ErrorInfo, ReactNode } from "react";
import axios from "axios";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
    sendErrorLogToServer({
      message: error.toString(),
      stack: errorInfo.componentStack ?? "Stack trace not available",
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="loader-container">
          <h1>Something went wrong.</h1>
        </div>
      );
    }

    return this.props.children;
  }
}

interface ErrorLogData {
  message: string;
  stack: string;
}

function sendErrorLogToServer(errorData: ErrorLogData) {
  axios
    .post("/api/logs/log_js_error.php", errorData)
    .catch((error) => console.error("Error sending log:", error));
}

export default ErrorBoundary;
