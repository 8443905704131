import { FC, useEffect, useState } from "react";
// import LoanChart from "../components/loanChart";
import { useSelector } from "react-redux";
import MyLoans from "../components/myLoans";
import {
  Typography,
  Spin,
  Modal,
  Button,
  Form,
  InputNumber,
  message,
  Select,
  Radio,
} from "antd";
import WebApp from "@twa-dev/sdk";
import { useTranslation } from "react-i18next";
import { MainButton } from "@twa-dev/sdk/react";
import { fetchLoans, createLoan } from "../helpers/api";
import { RootState } from "../services/store";
import { Loan, LoanFormValues, AvailableLoan } from "../types";

const { Title } = Typography;
const { Option } = Select;

const LoanInsure: FC = () => {
  const { t } = useTranslation();

  const { tonPrice } = useSelector((state: RootState) => state.ton);
  const [myLoans, setMyLoans] = useState<Loan[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [modalVisible, setModalVisible] = useState(false);
  const [availableLoans, setAvailableLoans] = useState<AvailableLoan[]>([]);
  const [tokenType, setTokenType] = useState("USDT");
  const [filteredLoans, setFilteredLoans] = useState<AvailableLoan[]>([]);
  const [form] = Form.useForm();

  useEffect(() => {
    WebApp.BackButton.show();
    WebApp.BackButton.onClick(() => window.history.back());

    return () => {
      WebApp.BackButton.hide();
    };
  }, []);

  useEffect(() => {
    const getLoans = async () => {
      setLoading(true);
      try {
        const data = await fetchLoans(page, itemsPerPage);
        setMyLoans(data.myLoans);
        setAvailableLoans(data.availableLoans);
      } catch (error) {
        console.error("Error fetching loans", error);
      } finally {
        setLoading(false);
      }
    };

    getLoans();
  }, [page, itemsPerPage, form]);

  useEffect(() => {
    const newFilteredLoans = availableLoans.filter((loan) => {
      if (tokenType === "USDT") {
        return loan.currency === "USDT";
      }
      return loan.currency !== "USDT";
    });
    setFilteredLoans(newFilteredLoans);

    form.resetFields(["loanId"]);
  }, [tokenType, availableLoans, form]);

  const handleLoanClick = () => {
    setModalVisible(true);
  };

  const handleCancelModal = () => {
    setModalVisible(false);
  };

  const handleFormSubmit = async (values: LoanFormValues) => {
    try {
      await createLoan(values).then((res) => {
        if (res.status === "success") {
          message.success(
            t("The loan application has been successfully submitted")
          );
          form.resetFields();
          setModalVisible(false);
          setAvailableLoans((prevAvailableLoans) =>
            prevAvailableLoans.filter((loan) => loan.loan_id !== values.loanId)
          );
        } else {
          const errorMessage = res.message;
          const availableAmount = res.availableAmount;
          const availableTerm = res.availableTerm;
          if (availableAmount) {
            message.error(
              t(`${errorMessage}. Available amount: ${availableAmount} TON`)
            );
          } else if (availableTerm) {
            message.error(
              t(`${errorMessage}. Available term: ${availableTerm} days`)
            );
          } else {
            message.error(t(errorMessage));
          }
        }
      });
    } catch (error: any) {
      message.error(t("Error submitting loan"));
    }
  };

  return (
    <div style={{ padding: "20px", backgroundColor: "#fff" }}>
      <Title level={3}>{t("Take out a loan")}</Title>
      {loading ? (
        <Spin size="large" />
      ) : (
        <>
          <MyLoans loans={myLoans} />
        </>
      )}
      <Modal
        title={t("Apply for a Loan")}
        open={modalVisible}
        onCancel={handleCancelModal}
        footer={[
          <Button key="cancel" onClick={handleCancelModal}>
            {t("Cancel")}
          </Button>,
          <Button key="submit" type="primary" form="loanForm" htmlType="submit">
            {t("Submit")}
          </Button>,
        ]}
      >
        <Form
          id="loanForm"
          form={form}
          layout="vertical"
          onFinish={handleFormSubmit}
        >
          <Form.Item
            name="currency"
            label={t("Select currency")}
            rules={[{ required: true, message: "Please select a currency" }]}
          >
            <Radio.Group
              defaultValue="USDT"
              buttonStyle="solid"
              style={{ marginBottom: 5 }}
              onChange={(e) => setTokenType(e.target.value)}
              value={tokenType}
            >
              <Radio.Button value="USDT">USDT</Radio.Button>
              <Radio.Button value="TON">TON</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="loanId"
            label={t("Available certificates")}
            rules={[{ required: true, message: "Please select a loan" }]}
          >
            <Select
              placeholder={t("Select a certificate")}
              style={{ width: "100%" }}
            >
              {filteredLoans.map((loan) => (
                <Option key={loan.loan_id} value={loan.loan_id}>
                  {`Loan ID: ${loan.loan_id} - Amount: ${loan.amount}`}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="amount"
            label={t("Amount")}
            extra={`Min. ${
              tokenType === "USDT"
                ? "50 USDT - Max. 1000 USDT"
                : `1 TON - Max. ${(1000 / tonPrice!).toFixed(0)} TON`
            }`}
            rules={[{ required: true, message: t("Please enter the amount") }]}
          >
            <InputNumber<number>
              min={tokenType === "USDT" ? 50 : 1}
              max={tokenType === "USDT" ? 1000 : 200}
              formatter={(value) => `${value}`}
              parser={(value) => parseFloat(value!.replace(/\$\s?|(,*)/g, ""))}
              style={{ width: "calc(100% - 30px)" }}
              addonBefore={
                <img
                  src={
                    tokenType === "USDT"
                      ? "./assets/UsdtLogo.webp"
                      : "./assets/tonIcon.png"
                  }
                  alt={tokenType === "USDT" ? "USDT Logo" : "TON Logo"}
                  width={12}
                />
              }
            />
          </Form.Item>
          <Form.Item
            name="term"
            label={t("Term (days)")}
            rules={[{ required: true, message: t("Please enter the term") }]}
          >
            <InputNumber min={1} max={30} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="rate"
            label={t("Rate")}
            rules={[{ required: true, message: t("Please enter the rate") }]}
          >
            <InputNumber min={0.01} step={0.01} style={{ width: "100%" }} />
          </Form.Item>
        </Form>
      </Modal>
      {!modalVisible && (
        <MainButton
          text={t("Take out a loan")}
          onClick={handleLoanClick}
          disabled={loading}
        />
      )}
    </div>
  );
};

export default LoanInsure;
