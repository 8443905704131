import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import WebApp from "@twa-dev/sdk";
import { Typography, Image, Space, Card } from "antd";
import { RightCircleTwoTone } from "@ant-design/icons";

const { Title, Link } = Typography;

const Invest: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    WebApp.BackButton.show();
    WebApp.BackButton.onClick(() => window.history.back());

    return () => {
      WebApp.BackButton.hide();
    };
  }, []);
  return (
    <>
      <Title level={3}>{t("Invest In Wholesale P2P Lending")}</Title>
      <Image width={330} src="/assets/CreditCertificate.webp" />
      <Space direction="vertical" style={{ width: "100%", marginTop: 20 }}>
        <Card
          hoverable
          className="buttons"
          onClick={() => navigate("/invest/payment")}
        >
          <div className="card">
            <Typography.Text strong>
              {t("Automate Your Investments and Earn Fixed Returns")}
            </Typography.Text>
            <RightCircleTwoTone style={{ fontSize: "24px", marginLeft: 20 }} />
          </div>
        </Card>
        <Card
          hoverable
          className="buttons"
          onClick={() => navigate("/invest/marketing")}
        >
          <div className="card">
            <Typography.Text strong>
              {t("Marketing")}
            </Typography.Text>
            <RightCircleTwoTone style={{ fontSize: "24px", marginLeft: 20 }} />
          </div>
        </Card>
      </Space>
      <Link
        href="https://webtransfer.com/credit-certificate-100-telegram"
        target="_blank"
      >
        {t("Learn more...")}
      </Link>
    </>
  );
};

export default Invest;
