import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import WebApp from "@twa-dev/sdk";
import { Typography } from "antd";

const { Paragraph, Title } = Typography;

const Risks: React.FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    WebApp.BackButton.show();
    WebApp.BackButton.onClick(() => window.history.back());

    return () => {
      WebApp.BackButton.hide();
    };
  }, []);
  return (
    <>
      <Title level={3}>{t("risks")}</Title>
      <Paragraph>{t("risks text")}</Paragraph>
    </>
  );
};

export default Risks;
